<template>
  <div class="clues">
    <Header isNoBg="#0B0B0B" theme="dark" callBack @click="goBack">
      <template v-slot:default>第三轮-查看关键线索</template>
      <template v-slot:right>
        <img
          style="margin-right: 10px; width: 18px"
          class="tips"
          src="../../assets/images/tianliangle/fenxiang.png"
          alt=""
          @click="share"
        />
        <img
          @click="showTip = true"
          class="tips"
          src="../../assets/images/tips.png"
          alt=""
        />
      </template>
    </Header>

    <div class="container">
      <div style="position: relative; flex: 1">
        <div
          class="top"
          :class="isClick ? 'active' : selectVal.value ? 'active1' : null"
        >
          <img :src="selectVal.positivePic" alt="" />
          <div class="border">
            <div class="title"></div>
            <!-- <div class="title">【南山医院】</div> -->
            <div class="button" @click="look">翻开查看</div>
          </div>
        </div>
        <div
          class="top1"
          :class="isClick ? 'active' : selectVal.value ? 'active1' : null"
        >
          <img :src="selectVal.backPic" alt="" />
          <!-- <div class="border">
            <div class="title">【南山医院】</div>
            <div class="button">
              【血液检测】血液中，碳氧血红蛋白含量 严重超标。血液中含有酒精。
            </div>
          </div> -->
        </div>
      </div>
      <div class="bottom">
        <div class="info">*所有线索查看完成后有机会获得实体剧本</div>
        <div class="button">
          <div class="left" @click="prev">上一个</div>
          <div class="middle">{{ key + 1 }}/{{ gameList.length }}</div>
          <div class="right" @click="next">
            {{ key + 1 === gameList.length ? "完成" : "下一个" }}
          </div>
        </div>
      </div>
    </div>

    <Overlay :show="showTip" @click="showTip = false">
      <div class="wrapper" @click.stop>
        <div class="content">
          <div class="title">
            <img src="../../assets/images/tianliangle/rule.png" alt="" />
          </div>
          <div class="main">
            <div class="h1Content">
              <div class="h1" style="margin-bottom: 5px">游戏共三轮</div>
              <div class="h1">第一轮：<span>座位排列</span></div>
              <div class="h1">第二轮：<span>年龄排序</span></div>
              <div class="h1">第三轮：<span>查看线索</span></div>
            </div>
            <div class="h2Content">
              <div class="h2">
                <span>1.</span>座位排列正确可获得一个锦囊并进入到 下一轮
              </div>
              <div class="h2">
                <span>2.</span>年龄排序正确可获得一个绿丝带藏品并 进入到下一轮
              </div>
              <div class="h2">
                <span>3.</span>查看所有线索后可获得徽章藏品，前300
                人额外获得实体剧本
              </div>
            </div>
            <div class="info">
              *每轮只有一次游戏机会，观看一次视频 增加一次游戏机会
            </div>
          </div>
          <div class="bottom" @click="showTip = false">
            我准备好了，立即开始
          </div>
        </div>
      </div>
    </Overlay>
    <Overlay :show="showTip1" @click="showTip1 = false">
      <div class="wrapper1" @click.stop>
        <div class="top">所有线索查看完成， 恭喜您获得“徽章”藏品</div>
        <div class="middle">
          <img
            style="width: 250px"
            src="../../assets/images/tianliangle/address.png"
            alt=""
          />
        </div>
        <div class="bottom">
          <div class="button" @click="goPage">
            {{ !data.address ? "填写收货地址" : "查看地址" }}
          </div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>
    <Overlay :show="showTip2" @click="showTip2 = false">
      <div class="wrapper1" @click.stop>
        <div class="top">所有线索查看完成， 恭喜您获得“徽章”藏品</div>
        <div class="middle">
          <img
            style="width: 180px"
            src="../../assets/images/tianliangle/address1.png"
            alt=""
          />
        </div>
        <div class="bottom">
          <div class="button" @click="lookCommodity">查看藏品</div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>

    <Overlay :show="showTip4" @click="showTip4 = false">
      <div class="wrapper2">
        <div class="content">
          <div class="title">【未完成】</div>
          <div class="middle">
            <div class="info">还有线索未完成快去查看吧！</div>
            <div class="info">
              <span>{{ notKey + 1 }}</span
              >/20
            </div>
          </div>
        </div>
        <div class="bottom">
          <div
            class="next"
            @click="
              showTip4 = false;
              key = notKey;
            "
          >
            我知道了
          </div>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";
import { addBridgeForAndroidWebView } from "sdbridge-android";
export default {
  components: {
    Overlay,
  },
  data() {
    return {
      showTip: false,
      showTip1: false,
      showTip2: false,
      showTip3: false,
      showTip4: false,
      isClick: false,
      token: null,
      gameList: [],
      key: 0,
      notKey: 0,
      data: {},
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  mounted() {
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }

    this.token = this.$route.query.token;
    this.init();
  },
  computed: {
    selectVal() {
      if (!this.gameList.length) return {};
      return this.gameList[this.key];
    },
  },
  methods: {
    lookCommodity() {
      console.log("platform", this.$route.query);
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: 16 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 16 }));
        }
      } else {
        this.$router.push("/user");
      }
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        }
      } else {
        this.$router.back();
      }
    },
    share() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -3 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -3 }));
        }
      }
    },
    async init() {
      const res = await this.$api.runDawnGame({
        token: this.token,
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.gameList = res.data.clue || [];
        this.data = res.data;
        if (res.data.status == 1) {
          if (res.data.finish == 1) {
            this.showTip1 = true;
          } else {
            this.showTip2 = true;
          }
        }
      }
    },
    async look() {
      const res = await this.$api.flip({
        token: this.token,
        tid: this.selectVal.key,
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.isClick = true;
        this.gameList[this.key].value = 1;
      } else {
        this.$toast(res.msg);
      }
    },
    prev() {
      if (this.key > 0) this.key--;
      this.isClick = false;
    },
    next() {
      if (this.key < this.gameList.length - 1) {
        this.key++;
      } else {
        //
        const key = this.gameList.findIndex((e) => e.value == 0);
        if (key !== -1) {
          this.showTip4 = true;
          this.notKey = key;
        } else {
          if (this.data.finish == 1) {
            this.showTip1 = true;
          } else {
            this.showTip2 = true;
          }
        }
      }
      this.isClick = false;
    },
    goPage() {
      if (this.data.address) {
        this.$router.push(
          `/addressList?address=${this.data.address}&userName=${this.data.userName}&tel=${this.data.tel}`
        );
      } else {
        this.$router.push(
          `/addAddress?id=${this.$route.query.id}&token=${this.$route.query.token}`
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clues {
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .container {
    padding: 11px 17px;
    background: url(../../assets/images/tianliangle/bg.jpeg);
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      // background-image: url(../../assets/images/tianliangle/cluesBg.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-color: rgb(141, 0, 10, 0.5);
      padding: 15px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      backface-visibility: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        top: 0;
        max-width: 100%;
      }
      &.active {
        animation: flip1 1.5s linear;
        animation-fill-mode: forwards;
      }
      &.active1 {
        transform: rotateY(180deg);
      }
      .border {
        // border: 1px solid rgb(255, 255, 255, 0.5);
        display: block;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 99;
        .title {
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
        .button {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          width: 113px;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .top1 {
      // background-image: url(../../assets/images/tianliangle/cluesBg2.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      padding: 15px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: rotateY(180deg);
      backface-visibility: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        top: 0;
        max-width: 100%;
      }
      &.active {
        animation: flip2 1.5s linear;
        animation-fill-mode: forwards;
      }
      &.active1 {
        transform: rotateY(0);
      }
      .border {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        background: rgb(229, 229, 229, 0.8);
        flex-direction: column;
        padding: 24px 25px 30px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .title {
          text-align: center;
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-bottom: 25px;
        }
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
      }
    }
    .bottom {
      width: 100%;
      margin-top: 10px;
      .info {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #e83428;
        margin-bottom: 10px;
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          width: 113px;
          height: 45px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 5px;
        }
        .middle {
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b9b692;
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          width: 113px;
          height: 45px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 5px;
        }
      }
    }
  }
  .tips {
    width: 20px;
  }
  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-size: cover;
    background-image: url(../../assets/images/tianliangle/tipsBg.jpg);
    background-repeat: no-repeat;
    padding: 17px 14px;
    display: flex;
    .content {
      flex: 1;
      background: rgba(255, 255, 255, 0.84);
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        width: 110px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .main {
        flex: 1;
        text-align: left;
        width: 100%;
        margin: 17px 0 35px;
        .h1 {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 23px;
          span {
            font-weight: normal;
          }
        }
        .h2 {
          span {
            font-weight: 500;
            color: #c00000;
            display: inline-block;
            margin-right: 5px;
          }
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 23px;
        }
        .info {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c00000;
          line-height: 20px;
        }
        .h1Content {
        }
        .h2Content {
          margin: 15px 0 20px;
        }
      }
      .bottom {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #e5e5e5;
        width: 100%;
        height: 40px;
        background: #8d000a;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
  .wrapper1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 40px 14px 20px;
    display: flex;
    background: #fff;
    flex-direction: column;
    .top {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      width: 60%;
      margin: 0 auto;
      text-align: center;
    }
    .middle {
      text-align: center;
      margin: 30px 0;
      img {
        width: 170px;
      }
    }
    .bottom {
      text-align: center;
      .button {
        font-size: 15px;
        margin: 0 auto;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8d000a;
        width: 145px;
        height: 40px;
        border: 1px solid #8d000a;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }
      .back {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .wrapper2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 15px;
    display: flex;
    background-image: url(../../assets/images/tianliangle/boxBg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    .content {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 15px;
      .title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .middle {
        margin: 20px 0 20px;
        img {
          width: 140px;
        }
      }
      .info {
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
        &:last-child {
          margin-top: 20px;
        }
        span {
          color: rgba(141, 0, 10, 1);
        }
      }
    }
    .bottom {
      text-align: center;
      margin-top: 20px;
      .next {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 150px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
      }
      .back {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  @keyframes flip1 {
    0% {
      transform: rotateY(0deg);
    }

    100% {
      transform: rotateY(180deg);
    }
  }

  @keyframes flip2 {
    0% {
      transform: rotateY(180deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }
}
/deep/.van-overlay {
  z-index: 999;
}
</style>
